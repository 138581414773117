
import _ from 'lodash';
import { initialState } from './store';

export const CLOSED_INDEX = 10;
let warningTimeout;

export function createActions(store) {
    // Actions mutate state and require a store update to fire
    const dataActions = {
        toggleMenu: (isOpen) => {
            store.data.isMenuOpen = typeof isOpen === 'boolean' ? isOpen :!store.data.isMenuOpen;
        },

        selectPage: pageIndex => {
            store.data.selectedPageIndex = pageIndex;
            store.actions.toggleMenu(false);
        },

        toggleScore: (color, index) => {
            const {locked, indexes} = store.data.scoreSelections[color];
            const isClosed = _.includes(indexes, CLOSED_INDEX);

            if (locked) {
                store.actions.displayWarning('Row is locked');
                return;
            }
            if (isClosed && index !== CLOSED_INDEX) {
                store.actions.displayWarning('Row is closed');
                return;
            }
            if (!isClosed && index === CLOSED_INDEX && indexes.length < 5) {
                store.actions.displayWarning(`At least 5 X's`);
                return;
            }
            if (index < _.max(indexes)) {
                store.actions.displayWarning('Invalid selection');
                return;
            }
            store.data.scoreSelections[color].indexes = _.xor(indexes, [index]);
        },

        toggleLock: (color) => {
            const {locked, indexes} = store.data.scoreSelections[color];

            if (_.includes(indexes, CLOSED_INDEX)) {
                store.actions.displayWarning('Row is closed');
                return;
            }

            store.data.scoreSelections[color].locked = !locked;
        },

        resetScore: () => {
            store.data.scoreSelections = initialState.scoreSelections;
            store.actions.toggleMenu();
        },

        displayWarning: (message) => {
            store.data.warningMessage = message;
            store.data.isShowingWarning = true;
            clearTimeout(warningTimeout);
            warningTimeout = setTimeout(() => {
                store.data.isShowingWarning = false;
                store.storeUpdate();
            }, 500);
        }
    };

    // Actions that do not mutate state
    const simpleActions = {
        toggleFullScreen: () => {
            var doc = window.document;
            var docEl = doc.documentElement;

            var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
            var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

            if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
                requestFullScreen.call(docEl);
            }
            else {
                cancelFullScreen.call(doc);
            }
            store.actions.toggleMenu();
        },
    }

    return {
        ...simpleActions,
        ..._.mapValues(dataActions, action => (...args) => {
            const result = action(...args);
            store.storeUpdate();
            return result;
        }),
    }
}