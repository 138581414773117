import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import LockIcon from '../Icons/LockedIcon';
import CrossIcon from '../Icons/CrossIcon';

function range(ascending) {
    return ascending ? _.range : _.rangeRight;
}

const Row = props => {
    const { selections, color, ascending, onSelect = _.noop, onLock = _.noop, renderLockLine = true } = props;
    const maxSelected = _.max(selections.indexes);
    const numbers = range(ascending)(2, 13);
    const isClosed = _.includes(selections.indexes, 10);

    return (
        <div className={classnames('Row', color, { locked: renderLockLine && (selections.locked || isClosed) })} >
            {numbers.map((number, index) => {
                const isSelected = _.includes(selections.indexes, index);
                return <div
                    key={number}
                    className={classnames('Row-square', color, { disabled: !isSelected && index < maxSelected })}
                    onClick={() => onSelect(index)}
                >
                    {number}
                    {isSelected &&
                        <CrossIcon />
                    }
                </div>
            })}
            <div className="Row-lockLine" />
            <div className={classnames('Row-square', 'lock', color)} onClick={onLock}>
                <LockIcon />
                {_.includes(selections.indexes, 10) &&
                    <CrossIcon />
                }
            </div>
        </div>
    )

};


export default Row;