import React from 'react';

const LockedIcon = (props) => {
    const {
        size = '15',
        x = '0px',
        y = '0px',
    } = props;

    return (
        <svg
            {...props}
            height={size} width={size} x={x} y={y} viewBox="0 0 100 100"
            className="Icon LockIcon"
        >
            <g>
            <path d="M82.5,42h-11V28.5C71.5,16.645,61.855,7,50,7c-11.855,0-21.5,9.645-21.5,21.5c0,0.829,0.671,1.5,1.5,1.5s1.5-0.671,1.5-1.5  C31.5,18.299,39.799,10,50,10s18.5,8.299,18.5,18.5V42h-51c-2.206,0-4,1.794-4,4v45c0,2.206,1.794,4,4,4h65c2.206,0,4-1.794,4-4V46  C86.5,43.794,84.706,42,82.5,42z M56.771,80H43.229l1.906-11.438C42.591,66.92,41,64.062,41,61c0-4.963,4.038-9,9-9  c4.963,0,9,4.037,9,9c0,3.063-1.592,5.921-4.136,7.562L56.771,80z"></path>
            </g>
        </svg>
    );
}

export default LockedIcon;