export const COLORS = {
    lights: 'rgb(200, 200, 17)',
    powerOn: 'rgb(65, 173, 73)',
    powerOff: '#BBB',
    locked: 'rgb(70,154,70)',
    unlocked: 'rgb(201, 0, 0)',
    motionActive: 'rgb(200, 200, 17)'
}

export const SCORE_ROWS = [
    {
        color: 'red',
        ascending: true
    },
    {
        color: 'yellow',
        ascending: true
    },
    {
        color: 'green',
        ascending: false
    },
    {
        color: 'blue',
        ascending: false
    },
];

export const SCORE_VALUES = {
    0: 0,
    1: 1,
    2: 3,
    3: 6,
    4: 10,
    5: 15,
    6: 21,
    7: 28,
    8: 36,
    9: 45,
    10: 55,
    11: 66,
    12: 78,
}

export const PageIndex = {
    SCORE_SHEET: 0,
    INSTRUCTIONS: 1,
}