import React, { useState, useEffect } from 'react';
import ScoreSheet from '../containers/ScoreSheet/ScoreSheet';
import Instructions from '../containers/Instructions/Instructions';
import Menu from '../components/Menu/Menu';


const Pages = [
    ScoreSheet,
    Instructions,
];


const App = props => {
    const [store, setStore] = useState(props.store);
    const [forceRender, setForceRender] = useState();


    const updateState = (updatedStore) => {
        setForceRender(Date.now());
        setStore(updatedStore);
    };

    useEffect(() => {
        store.subscribe(updateState);
        return () => store.unsubscribe(updateState);
    }, []);

    const { data, actions } = store;
    const { selectedPageIndex } = data;
    const PageComponents = Pages[selectedPageIndex];

    return data.isInitialized ? (
        <div className='App'>
            <div className="Page">
                <PageComponents data={data} actions={actions} />
                <Menu data={data} actions={actions} />
            </div>
        </div>
    ) : null
};


export default App;