
import _ from 'lodash';
import { getStoreState, setStoreState } from './storage';
import { createActions } from './actions';
import { updateLocale } from 'moment';


export const initialState = {
    selectedPageIndex: 0,
    isMenuOpen: false,
    isShowingWarning: false,
    scoreSelections: {
        red: {
            indexes: [],
            locked: false,
        },
        yellow: {
            indexes: [],
            locked: false,
        },
        green: {
            indexes: [],
            locked: false,
        },
        blue: {
            indexes: [],
            locked: false,
        },
        negative: {
            indexes: [],
        },
    }
};

export default class Store {
    listeners = new Set();
    data = _.cloneDeep(initialState);

    constructor() {
        const self = this;
        getStoreState().then(savedState => {
            _.assign(self.data, {
                isInitialized: true,
                ...savedState
            });
            this.storeUpdate();
        });
    }

    saveData = _.debounce(() => setStoreState(this.data), 500)

    subscribe = (callback) => {
        this.listeners.add(callback);
    }

    unsubscribe = (callback) => {
        this.listeners.delete(callback);
    }

    storeUpdate = () => {
        this.data = _.cloneDeep(this.data);
        this.listeners.forEach(callback => callback(this));
        this.saveData();
    }

    actions = createActions(this)
}
