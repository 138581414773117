
import React, { useRef, useEffect, useState } from 'react';
import Sheet from '../../components/Sheet/Sheet';


const ScoreSheet = props => {
    const { data, actions } = props;
    const { scoreSelections, warningMessage, isShowingWarning } = data;
    const { toggleMenu } = actions;
    const sheetRef = useRef();
    const [scalePercent, setScalePercent] = useState(1);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);


    const updateScale = () => {
        if (sheetRef.current) {
            const { clientWidth, clientHeight } = sheetRef.current;
            const widthScale = window.innerWidth / clientWidth;
            const heightScale = window.innerHeight / clientHeight;
            const percent = Math.min(widthScale, heightScale);

            const newWidth = clientWidth * percent;
            const newHeight = clientHeight * percent;
            const x = Math.abs((newWidth - window.innerWidth) / 2);
            const y = Math.abs((newHeight - window.innerHeight) / 2);
            setTranslateX(x);
            setTranslateY(y);
            setScalePercent(percent);
        }
    };

    useEffect(() => {
        updateScale();
    }, [sheetRef]);

    useEffect(() => {
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, []);

    return (
        <div className="ScoreSheet"
            ref={sheetRef}>
            <Sheet
                actions={actions}
                scoreSelections={scoreSelections}
                warningMessage={warningMessage}
                isShowingWarning={isShowingWarning}
                toggleMenu={toggleMenu}
                style={{
                    transform: `translate(${translateX}px, ${translateY}px) scale(${scalePercent})`
                }} />
        </div>
    )
};

export default ScoreSheet;