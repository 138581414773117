import React from 'react';
import _ from 'lodash';
import DiceIcon from '../Icons/DiceIcon';
import CrossIcon from '../Icons/CrossIcon';

const Penalty = props => {
    const {toggleScore=_.noop, indexes=[]} = props;

    return (
        <div className="Penalty">
            <div className="Penalty-negativeIcon">
                <DiceIcon />
                <span className="Penalty-negativeX">
                    <CrossIcon />
                </span>
            </div>
            {_.range(1, 5).map((number, index) => (
                <div
                    key={number}
                    className="Penalty-negativeSquare"
                    onClick={() => toggleScore('negative', index)}
                >
                    {_.includes(indexes, index) &&
                        <CrossIcon size="1.5rem" />
                    }
                </div>
            ))}
        </div>

    );
};

export default Penalty;