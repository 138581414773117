import _ from 'lodash';
import localforage from 'localforage';

localforage.config({
    name: 'home-control',
    storeName: 'ui-state',
});

const STORE_KEY = 'store';

const persistKeys = [
    'scoreSelections',
    'selectedPageIndex',
];

function set(key, value) {
    return localforage.setItem(key, value);
}

function get(key) {
    return localforage.getItem(key);
}

function getStoreState() {
    return get(STORE_KEY);
}

function setStoreState(state) {
    const persist = _.pick(state, ...persistKeys);
    return set(STORE_KEY, persist);
}

export {
    get,
    set,
    getStoreState,
    setStoreState
}