import _ from 'lodash';
import React, { useRef, useEffect, useState } from 'react';
import Row from '../Row/Row';
import DiceIcon from '../Icons/DiceIcon';
import CrossIcon from '../Icons/CrossIcon';
import { SCORE_ROWS } from '../../lib/constants';
import Score from '../Score/Score';
import { initialState } from '../../lib/store';
import Penalty from '../Penalty/Penalty';



const Sheet = props => {
    const { scoreSelections = _.cloneDeep(initialState.scoreSelections), warningMessage, isShowingWarning, actions = {}, style, renderLockLine=true } = props;
    const { toggleScore = _.noop, toggleLock = _.noop, toggleMenu = _.noop } = actions;
    

    return (
        <div
            className="Sheet"
            style={style}
        >
            <div className="Sheet-warning" style={{ opacity: isShowingWarning ? 1 : 0 }}>
                <span className="Sheet-warningMessage">
                    {warningMessage}
                </span>
            </div>
            {SCORE_ROWS.map(({ color, ascending }) => (
                <Row
                    key={color}
                    color={color}
                    ascending={ascending}
                    selections={scoreSelections[color]}
                    onSelect={index => toggleScore(color, index)}
                    onLock={() => toggleLock(color)}
                    renderLockLine={renderLockLine}
                />
            ))}
            <div className="Sheet-negativeRow">
                <div className="Sheet-options" onClick={toggleMenu}>
                    Options
                </div>
                <Penalty toggleScore={toggleScore} indexes={scoreSelections.negative.indexes} />
            </div>
            <Score scoreSelections={scoreSelections} />
        </div>
    )
};

export default Sheet;