import React from 'react';

const CrossIcon = (props) => {
    const {
        size = '22',
        x = '0px',
        y = '0px',
    } = props;

    return (
        <svg
            {...props}
            height={size} width={size} x={x} y={y} viewBox="0 0 100 100"
            className="Icon CrossIcon"
        >
            <g>
            <path d="M87.407 12.593l0 0c-3.24569,-3.24569 -8.55479,-3.24569 -11.7993,0l-25.6077 25.6077 -25.6077 -25.6077c-3.24451,-3.24569 -8.55361,-3.24569 -11.7993,0l0 0c-3.24569,3.24569 -3.24569,8.55479 0,11.7993l25.6077 25.6077 -25.6089 25.6077c-3.24569,3.24569 -3.24569,8.55479 0,11.8005l0 0c3.24569,3.24569 8.55479,3.24569 11.8005,0l25.6077 -25.6077 25.6077 25.6077c3.24569,3.24569 8.55479,3.24569 11.8005,0l0 0c3.24569,-3.24569 3.24569,-8.55479 0,-11.8005l-25.6089 -25.6077 25.6077 -25.6077c3.24569,-3.24451 3.24569,-8.55361 0,-11.7993z"></path>
            </g>
        </svg>
    );
}

export default CrossIcon;