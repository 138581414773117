import React from 'react';
import CrossIcon from '../../components/Icons/CrossIcon';
import Sheet from '../../components/Sheet/Sheet';
import Row from '../../components/Row/Row';
import { PageIndex, SCORE_ROWS } from '../../lib/constants';
import AllDice from '../../components/Icons/AllDice';
import Penalty from '../../components/Penalty/Penalty';
import Scoring from '../../components/Icons/Scoring';
import { initialState } from '../../lib/store';

const setupExampleScores = {
    ..._.cloneDeep(initialState.scoreSelections),
    red: { indexes: [3, 5] },
    yellow: { indexes: [1, 2, 5, 6, 8] },
    green: { indexes: [0, 2, 6] },
    blue: { indexes: [2] }
};

const scoringExampleScores = {
    ..._.cloneDeep(initialState.scoreSelections),
    red: { indexes: [0, 1, 4, 6] },
    yellow: { indexes: [1, 3, 6] },
    green: { indexes: [1, 2, 4, 5, 6, 9, 10] },
    blue: { indexes: [0, 2, 3, 4, 5, 7, 9] },
    negative: { indexes: [0, 1] }
};

const Instructions = props => {
    const { data, actions } = props;
    const { toggleMenu, selectPage } = actions;

    return (
        <div className="Instructions">
            <div className="Instructions-close" onClick={() => selectPage(PageIndex.SCORE_SHEET)}>
                <CrossIcon />
            </div>
            <div className="Instructions-sections">
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        CONTENTS
                </h1>
                    <div className="Instructions-content">
                        6 dice ( 2 white,1 red, 1 green, 1 blue, 1 yellow)
                        1 score pad
                </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        OBJECT
                </h1>
                    <div className="Instructions-content">
                        Score the most points by crossing out as many
                        numbers in the four color-rows as possible
                        while avoiding penalty points.
                </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        SETUP
                </h1>
                    <div className="Instructions-content">
                        Each player takes a score sheet and something
                        to write with.
                        Before playing it’s important to understand the
                        one basic rule of Qwixx, which is that numbers
                        must be crossed out from left to right in each of the
                        four color-rows. You do not have to begin with
                        the number farthest to the left, but if you skip any
                        numbers, they cannot be crossed out afterward.
                </div>
                    <div className="Instructions-image">
                        <Sheet scoreSelections={setupExampleScores} renderLockLine={false} />
                    </div>
                    <div className="Instructions-example">
                        In the red row first the 5 and then the
                        7 were crossed out. Therefore, the red 2, 3, 4
                        and 6 may not be crossed out later in the game.
                        In the yellow row only the 11 and the 12 may
                        still be crossed out. In the green row you can
                        only cross out numbers to the right of the 6.
                        In the blue row you can only cross out right of
                        the 10.
                </div>
                    <div className="Instructions-tip">
                        Click the lock icon to put a small horizontal line through the
                        numbers that you skipped, so that you don’t
                        cross them out later by accident
                </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        HOW TO PLAY
                    </h1>
                    <div className="Instructions-content">
                        The first player to roll a 6 takes on the role of
                        “active player.” The active player rolls all six
                        dice. The following two actions are now carried
                        out in order, <span className="bold">always one after the other:</span>
                        <div className="Instructions-step">
                            <div className="Instructions-stepNumber">
                                1.
                            </div>
                            <div className="Instructions-stepText">
                                The active player
                                adds up the <span className="bold">two
                                white dice</span> and
                                announces out loud,
                                the resulting sum.
                                All players may then
                                (but are not required
                                to) cross out the
                                number that the active
                                player announced
                                in any (but only one)
                                of the color-rows.
                            </div>

                            <div className="Instructions-stepImage">
                                <AllDice width={200} height={220} circledDice={[1, 2]} />
                            </div>
                        </div>
                        <div className="Instructions-example">
                            Chris is the active player. The two white
                            dice show a 4 and a 1. Chris announces, “five.”
                            Tony crosses out the yellow 5 on his score sheet.
                            Caroline crosses out the red 5. Annie and Nora
                            choose not to cross out a number.
                    </div>
                        <div className="Instructions-step">
                            <div className="Instructions-stepNumber">
                                2.
                        </div>
                            <div className="Instructions-stepText">
                                The active player
                                (but not the others)
                                may then (but is not
                                required to) <span className="bold">add one
                                of the white dice </span>
                                together with any one
                                of the colored dice
                                and cross out the
                                number corresponding
                                to this sum in the
                                color-row corresponding
                                to the color of the
                                chosen die.
                        </div>
                            <div className="Instructions-stepImage">
                                <AllDice width={200} height={220} circledDice={[1, 4]} />

                            </div>
                        </div>
                        <div className="Instructions-example">
                            Chris adds together the white 4 with
                            the blue 6 and crosses out the 10 in the blue
                            color-row.
                    </div>
                    </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        PENALTIES
                </h1>
                    <div className="Instructions-content">
                        <div className="Instructions-imageFloat">
                            <Penalty indexes={[0]} />
                        </div>
                        If, after the two actions, the active
                        player doesn’t cross out at least
                        one number, he must cross out one
                        of the penalty boxes. Each penalty box is worth
                        -5 points at the end of the game. (The non-active
                        players do not take a penalty if they choose not
                        to cross out a number.)
                </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        END OF A ROUND
                </h1>
                    <div className="Instructions-content">
                        Once all players are ready, the player to the left
                        becomes the new active player and re-rolls all
                        six dice. Then the two actions described on the
                        left are carried out again, one after the other
                </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        LOCKING A ROW
                </h1>
                    <div className="Instructions-content">
                        If you wish to cross out the number at the
                        extreme right end of a color-row (red 12, yellow
                        12, green 2, blue 2) you must have first
                        crossed out at least five numbers in that row.
                        If you cross out the number on the extreme
                        right, then also cross off the lock symbol directly
                        next to it. This indicates that the color-row is
                        now locked for all players and numbers of this
                        color cannot be crossed out in future rounds.
                        The die of the correspondi
                </div>

                    <div className="Instructions-image">
                        <Row
                            color='green'
                            ascending={false}
                            renderLockLine={false}
                            selections={{ indexes: [1, 2, 3, 4, 5, 9, 10] }}
                        />
                    </div>
                    <div className="Instructions-example">
                        Alison crosses out the green 2 and
                        after it, the lock symbol. The green die is removed
                        from the game.
                    </div>
                    <div className="Instructions-notes">
                        <ul>
                            <li>
                                If a row is locked during the first action, it is
                                possible that other players may, at the same
                                time, also cross out the number on the extreme
                                right and lock the same color-row. These
                                players must also have previously crossed out
                                at least five numbers in that row.
                        </li>
                            <li>
                                The cross on the lock counts toward the total
                                number of crosses marked in that color-row.
                        </li>
                        </ul>
                    </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        ENDING THE GAME
                </h1>
                    <div className="Instructions-content">
                        The game ends immediately as soon as either
                        someone has marked a cross in his fourth
                        penalty box or as soon as two dice have been
                        removed from the game (two color-rows have
                        been locked). It may occur (during the first
                        action) that a third row is locked simultaneously
                        with the second row
                </div>
                </section>
                <section className="Instructions-section">
                    <h1 className="Instructions-header">
                        SCORING
                </h1>
                    <div className="Instructions-content">
                        Below is a table indicating
                        how many points are awarded for how many
                        crosses within each row (including any locks
                        marked with a cross). Each crossed out penalty
                        box scores five minus-points. Enter your points
                        for the four color-rows and the minus-points for
                        any penalties in the appropriate fields at the
                        bottom of the scoresheet. The player with the
                        highest total score is the winner.
                </div>
                    <div className="Instructions-image">
                        <Scoring size={3.5} />
                    </div>
                    <div className="Instructions-image">
                        <Sheet scoreSelections={scoringExampleScores} renderLockLine={false} />
                    </div>
                    <div className="Instructions-example">
                        Alison has 4 crosses in red, which
                        gives her 10 points; in yellow, she has 3 (=6
                        points); in green 8 (=36 points); and in blue 7
                        (= 28 points). For her two penalties she must
                        subtract 10 points. This means that Alison’s total
                        score is 70 points.
                </div>
                </section>
            </div>
        </div>
    );
};

export default Instructions;