import React from 'react';
import classnames from 'classnames';
import CrossIcon from '../Icons/CrossIcon';
import { PageIndex } from '../../lib/constants';

const Menu = props => {
    const {actions, data} = props;
    const {isMenuOpen} = data;
    const {resetScore, toggleFullScreen, toggleMenu, selectPage} = actions;

    return (
        <div className={classnames('Menu', {open: isMenuOpen})}>
            <div className="Menu-close" >
                <CrossIcon onClick={toggleMenu}/>
            </div>
            <div className="Menu-option" onClick={() => selectPage(PageIndex.INSTRUCTIONS)}>
                Instructions
            </div>
            <div className="Menu-option" onClick={resetScore}>
                Reset Score
            </div>
            <div className="Menu-option" onClick={toggleFullScreen}>
                Full Screen
            </div>
        </div>
    )
};

export default Menu;