import React from 'react';
import classnames from 'classnames';
import { SCORE_ROWS, SCORE_VALUES } from '../../lib/constants';


const Score = props => {
    const { scoreSelections } = props;
    const negativeScore = scoreSelections.negative.indexes.length * 5;

    const calculateScore = color => {
        const count = scoreSelections[color].indexes.length +
            (_.includes(scoreSelections[color].indexes, 10) ? 1 : 0);
        return SCORE_VALUES[count];
    };

    const total = _.reduce(scoreSelections, (acc, selection, color) => {
        if (color === 'negative') {
            return acc;
        }
        return acc + calculateScore(color);
    }, 0) - negativeScore;

    return (
        <div className="Score">
            <div className="Score-label">
                totals
            </div>
            {SCORE_ROWS.map(({ color }, index) => {
                const score = calculateScore(color);
                return <React.Fragment key={color} >
                    {index > 0 &&
                        <div className="Score-operator">+</div>
                    }
                    <div className={classnames('Score-color', color)}>
                        {score > 0 ? score : ''}
                    </div>
                </React.Fragment>
            })}
            <div className="Score-operator subtract">-</div>
            <div className="Score-color negative">
                {negativeScore > 0 && negativeScore}
            </div>
            <div className="Score-operator">=</div>
            <div className="Score-total">
                {total !== 0 && total}
            </div>
        </div>
    )
};

export default Score;