import React from "react";

const ORIGINAL_WIDTH = 113.786;
const ORIGINAL_HEIGHT = 14.323;

const Scoring = (props) => {
    const { size } = props;
    const width = ORIGINAL_WIDTH * size;
    const height = ORIGINAL_HEIGHT * size;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            version="1.1"
            viewBox="0 0 30.106 3.79"
            className="Scoring"
        >
            <g transform="translate(-125.554 -190.784)">
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M126.132 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M126.132 190.837v.052h-.005a.606.606 0 00-.258.074c-.11.065-.209.173-.21.399V194a.61.61 0 00.074.259c.065.109.172.208.398.21H127.233c.026-.001.148-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.173-.208-.399-.21h-1.096v-.105h1.096c.278-.002.434.146.505.29.072.143.073.28.073.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M128.664 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .526-.006.526-.526v-2.633c0-.005-.006-.525-.526-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M128.664 190.837v.052h-.005a.606.606 0 00-.258.074c-.11.065-.209.173-.21.399V194a.61.61 0 00.074.259c.065.109.172.208.399.21h1.101c.026-.001.149-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.172-.208-.399-.21h-1.096v-.105h1.096c.278-.002.434.146.505.29.072.143.073.28.073.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M131.196 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.097c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.097"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M131.196 190.837v.052h-.005a.605.605 0 00-.258.074c-.11.065-.208.173-.21.399V194c.001.027.01.15.074.259.065.109.173.208.399.21h1.101c.026-.001.149-.011.258-.074.11-.065.209-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.172-.208-.399-.21h-1.096v-.105h1.096c.279-.002.434.146.505.29.072.143.073.28.073.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.433-.146-.504-.29-.072-.143-.073-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M133.729 190.837c-.005 0-.526.006-.526.525v2.633c0 .005.006.526.526.526h1.096c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M133.729 190.837v.052h-.005a.606.606 0 00-.259.074c-.109.065-.208.173-.21.399V194a.61.61 0 00.074.259c.065.109.173.208.399.21h1.101c.026-.001.149-.011.258-.074.11-.065.209-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.172-.208-.399-.21h-1.096v-.105h1.096c.279-.002.434.146.505.29.072.143.073.28.073.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.433-.146-.504-.29-.072-.143-.073-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M136.26 190.837c-.004 0-.525.006-.525.525v2.633c0 .005.006.526.526.526h1.096c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M136.26 190.837v.052h-.004a.606.606 0 00-.258.074c-.11.065-.209.173-.21.399V194a.61.61 0 00.074.259c.064.109.172.208.398.21h1.101c.027-.001.15-.011.259-.074.109-.065.208-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.173-.208-.4-.21h-1.095v-.105h1.096c.278-.002.433.146.504.29.072.143.073.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.279.002-.434-.146-.505-.29-.072-.143-.073-.28-.073-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M138.793 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .526-.006.526-.526v-2.633c0-.005-.006-.525-.526-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M138.793 190.837v.052h-.005a.606.606 0 00-.258.074c-.11.065-.209.173-.21.399V194a.61.61 0 00.074.259c.064.109.172.208.398.21H139.894c.026-.001.148-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.173-.208-.4-.21h-1.095v-.105h1.096c.278-.002.434.146.504.29.073.143.073.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.279.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M141.325 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .526-.006.526-.526v-2.633c0-.005-.006-.525-.526-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M141.325 190.837v.052h-.005a.606.606 0 00-.258.074c-.11.065-.209.173-.21.399V194a.61.61 0 00.074.259c.065.109.172.208.399.21h1.101c.026-.001.148-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.607.607 0 00-.074-.258c-.065-.11-.173-.208-.399-.21h-1.096v-.105h1.096c.278-.002.434.146.504.29.073.143.074.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M143.857 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.097c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.097"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M143.857 190.837v.052h-.005a.606.606 0 00-.258.074c-.109.065-.208.173-.21.399V194c.001.027.01.15.075.259.064.109.172.208.398.21h1.101c.026-.001.149-.011.259-.074.109-.065.208-.173.21-.4v-2.638a.607.607 0 00-.075-.258c-.064-.11-.172-.208-.398-.21h-1.097v-.105h1.097c.278-.002.433.146.504.29.072.143.073.28.074.288v2.633c.002.279-.147.434-.29.505-.143.072-.28.073-.288.073h-1.097c-.278.002-.433-.146-.504-.29-.072-.143-.073-.28-.073-.288v-2.633c-.002-.279.146-.434.29-.505.142-.072.28-.073.287-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M146.39 190.837c-.005 0-.526.006-.526.525v2.633c0 .005.006.526.526.526h1.096c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M146.39 190.837v.052h-.005a.606.606 0 00-.259.074c-.109.065-.208.173-.21.399V194c.001.027.011.15.075.259.064.109.172.208.398.21h1.101c.027-.001.15-.011.259-.074.109-.065.208-.173.21-.4v-2.638a.607.607 0 00-.074-.258c-.065-.11-.173-.208-.399-.21h-1.096v-.105h1.096c.278-.002.433.146.504.29.072.143.073.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.279.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M148.922 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .525-.006.525-.526v-2.633c0-.005-.006-.525-.525-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M148.922 190.837v.052h-.004a.606.606 0 00-.259.074c-.109.065-.208.173-.21.399V194c.002.027.011.15.075.259.064.109.172.208.398.21H150.024c.026-.001.148-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.607.607 0 00-.074-.258c-.065-.11-.173-.208-.399-.21h-1.096v-.105h1.096c.278-.002.434.146.504.29.073.143.073.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.279.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M151.454 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .526-.006.526-.526v-2.633c0-.005-.006-.525-.526-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M151.454 190.837v.052h-.005a.605.605 0 00-.258.074c-.11.065-.209.173-.21.399V194c.001.027.01.15.074.259.065.109.172.208.399.21h1.101c.026-.001.148-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.173-.208-.399-.21h-1.096v-.105h1.096c.278-.002.434.146.504.29.073.143.074.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <path
                    fill="#fff"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M153.986 190.837c-.005 0-.525.006-.525.525v2.633c0 .005.006.526.525.526h1.096c.005 0 .526-.006.526-.526v-2.633c0-.005-.006-.525-.526-.525h-1.096"
                ></path>
                <path
                    fill="#868484"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M153.986 190.837v.052h-.005a.606.606 0 00-.258.074c-.11.065-.209.173-.21.399V194c.001.027.01.15.074.259.065.109.172.208.399.21h1.101c.026-.001.148-.011.258-.074.11-.065.208-.173.21-.4v-2.638a.608.608 0 00-.074-.258c-.065-.11-.173-.208-.399-.21h-1.096v-.105h1.096c.278-.002.434.146.504.29.073.143.074.28.074.288v2.633c.002.279-.146.434-.29.505-.143.072-.28.073-.288.073h-1.096c-.278.002-.434-.146-.504-.29-.073-.143-.074-.28-.074-.288v-2.633c-.002-.279.146-.434.29-.505.143-.072.28-.073.288-.073v.052"
                ></path>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 72.043 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            1x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 73.704 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0" y="0">
                            1
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 84.97 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            2x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 86.494 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0" y="0">
                            3
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 97.898 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            3x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 99.17 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0" y="0">
                            6
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 110.826 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            4x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 110.493 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 2.8181627" y="0">
                            10
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 123.753 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            5x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 123.446 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 2.9349375" y="0">
                            15
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 136.68 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            6x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 136.75 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 2.9071341" y="0">
                            21
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 149.609 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            7x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 149.445 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            28
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 162.537 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            8x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 162.372 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            36
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 175.146 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            9x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 174.982 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            45
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 186.663 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 2.8181627 6.1923957" y="0">
                            10x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 187.91 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            55
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 199.719 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 2.5623705 5.9366035" y="0">
                            11x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 200.752 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            66
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 212.46 265.858)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 2.9349375 6.3091702" y="0">
                            12x
            </tspan>
                    </text>
                </g>
                <g transform="matrix(.19612 0 0 -.19612 111.906 244.43)">
                    <text
                        style={{ InkscapeFontSpecification: "Futura-Medium" }}
                        fill="#231f20"
                        fillOpacity="1"
                        fillRule="nonzero"
                        stroke="none"
                        fontFamily="Futura"
                        fontSize="5.561"
                        fontVariant="normal"
                        fontWeight="500"
                        transform="matrix(1 0 0 -1 213.765 256.937)"
                        writingMode="lr-tb"
                    >
                        <tspan x="0 3.3742328" y="0">
                            78
            </tspan>
                    </text>
                </g>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M125.752 192.855h1.743v-.091h-1.743z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M128.287 192.855h1.744v-.091h-1.744z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M130.823 192.855h1.743v-.091h-1.743z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M133.358 192.855h1.744v-.091h-1.744z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M135.893 192.855h1.744v-.091h-1.744z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M138.429 192.855h1.743v-.091h-1.743z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M140.964 192.855h1.744v-.091h-1.744z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M143.5 192.855h1.743v-.091H143.5z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M146.035 192.855h1.743v-.091h-1.743z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M148.57 192.855h1.744v-.091h-1.744z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M151.106 192.855h1.743v-.091h-1.743z"
                ></path>
                <path
                    fill="#8e8c8c"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="0.353"
                    d="M153.641 192.855h1.744v-.091h-1.744z"
                ></path>
            </g>
        </svg>
    );
}

export default Scoring;